import {
  Card,
  Colours,
  Icon,
  ProductIcon,
  Products,
  Router
} from '@/Components'
import { MarketingLayout } from '@/Layouts'
import { cn } from '@/Lib'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import {
  ComponentPropsWithoutRef,
  ElementRef,
  ReactNode,
  forwardRef,
  useState
} from 'react'

Page.layout = (page: ReactNode) => <MarketingLayout>{page}</MarketingLayout>

export default function Page() {
  return (
    <div className='w-full'>
      <Hero />
      <Pricing />
      <Commitment />
      <DoPay />
      <FAQ />
    </div>
  )
}

function Hero() {
  return (
    <section className='p-4 py-20 sm:py-40'>
      <div className='mx-auto flex w-full max-w-5xl flex-col items-center justify-center gap-10 text-pretty'>
        <ProductIcon
          className='size-16 sm:size-24'
          shapes={0b1000_1001_0010_1001}
          colour={Colours.yellow}
        />
        <h3 className='flex flex-col text-center text-5xl font-black uppercase leading-none text-strong sm:text-7xl sm:leading-none'>
          <span>Pricing</span>
        </h3>
        <p className='mx-auto max-w-prose text-center text-2xl'>
          The cost? No arms. No legs. No hidden fees. Just 1 monthly
          subscription no matter how you invest.
        </p>
      </div>
    </section>
  )
}

function Pricing() {
  return (
    <section className='grid w-full grid-cols-2 justify-center bg-green-100 sm:min-h-max'>
      <div className='col-span-2 flex flex-col gap-6 bg-green-100 px-4 py-20 md:col-span-1 md:ml-auto md:max-w-lg md:px-10'>
        <div className='flex w-full flex-col gap-6'>
          <div className='flex w-full gap-6'>
            <ProductIcon
              shapes={Products.roots}
              colour={Colours.green}
              className='size-16'
            />
            <div className='flex w-full flex-col gap-2'>
              <h3 className='text-2xl font-medium text-strong sm:text-4xl'>
                Roots plan
              </h3>
              <span className='text-xl text-medium'>Free</span>
            </div>
          </div>
          <p className='text-lg sm:text-xl'>
            With the free Roots plan, there&apos;s no subscription fee. Every
            cent goes directly into your portfolio, giving you everything you
            need to start investing.
          </p>
          <h4 className='text-lg font-medium text-strong sm:text-xl'>
            What&apos;s included
          </h4>
          <div className='flex flex-col gap-4'>
            <div className='flex gap-4'>
              <Icon className='text-success'>check</Icon>
              <p className='text-lg sm:text-xl'>
                An emergency savings account backed by the Allan Gray Money
                Market Fund. Currently earning 7.57% per year.
              </p>
            </div>
            <div className='flex gap-4'>
              <Icon className='text-success'>check</Icon>
              <p className='text-lg sm:text-xl'>
                A Tax Free Savings Account (TFSA) with your choice of one of
                five curated funds, for maximum growth over a long period of
                time.
              </p>
            </div>
            <div className='flex gap-4'>
              <Icon className='text-success'>check</Icon>
              <p className='text-lg sm:text-xl'>
                Personalised target setting and recurring contributions based on
                your financial situation.
              </p>
            </div>
            <div className='flex gap-4'>
              <Icon className='text-success'>check</Icon>
              <p className='text-lg sm:text-xl'>
                Automation rules for your contributions based on your targets.
              </p>
            </div>
            <div className='flex gap-4'>
              <Icon className='text-success'>check</Icon>
              <p className='text-lg sm:text-xl'>
                No transaction fees for debit orders or EFTs.
              </p>
            </div>
          </div>

          <Router
            href={route('login', { protea: false })}
            className='flex w-full items-center justify-center rounded-full bg-green-500 p-6 text-center hover:bg-green-600 sm:max-w-max sm:px-20'
          >
            <span className='mx-auto text-lg font-medium leading-none text-on-color lg:text-xl'>
              Start for free
            </span>
          </Router>
        </div>
      </div>
      <div className='col-span-2 flex flex-col gap-6 bg-rose-100 px-4 py-20 md:col-span-1 md:px-10'>
        <div className='flex w-full max-w-lg flex-col gap-6'>
          <div className='flex w-full gap-6'>
            <ProductIcon
              shapes={Products.protea}
              colour={Colours.rose}
              className='size-16'
            />
            <div className='flex w-full flex-col gap-2'>
              <h3 className='text-2xl font-medium text-strong sm:text-4xl'>
                Protea plan
              </h3>
              <span className='text-xl text-medium'>R 100 per month</span>
            </div>
          </div>
          <p className='text-lg sm:text-xl'>
            Get a complete savings and investment portfolio in one place for a
            simple monthly subscription, with everything from the Roots plan
            plus more.
          </p>
          <h4 className='text-lg font-medium text-strong sm:text-xl'>
            What&apos;s included
          </h4>
          <div className='flex flex-col gap-4'>
            <div className='flex gap-4'>
              <Icon className='text-rose-400'>check</Icon>
              <p className='text-lg sm:text-xl'>
                Everything from the Roots plan.
              </p>
            </div>
            <div className='flex gap-4'>
              <Icon className='text-rose-400'>check</Icon>
              <p className='text-lg sm:text-xl'>
                Multiple savings pots to set money aside, track progress, and
                reach your goals faster.
              </p>
            </div>
            <div className='flex gap-4'>
              <Icon className='text-rose-400'>check</Icon>
              <p className='text-lg sm:text-xl'>
                Tax-Free Savings Accounts for your whole family.
              </p>
            </div>
            <div className='flex gap-4'>
              <Icon className='text-rose-400'>check</Icon>
              <p className='text-lg sm:text-xl'>
                Automatically split your contributions between your savings
                goals, your family members, and your TFSA.
              </p>
            </div>
          </div>

          <Router
            href={route('login', { protea: true })}
            className='mt-auto flex w-full items-center justify-center rounded-full bg-rose-400 p-6 text-center hover:bg-rose-500 sm:max-w-max sm:px-20'
          >
            <span className='mx-auto text-lg font-medium leading-none text-on-color lg:text-xl'>
              Start with Protea
            </span>
          </Router>
        </div>
      </div>
    </section>
  )
}

function Commitment() {
  return (
    <section className='bg-slate-50 p-4 py-20 sm:py-40'>
      <div className='mx-auto flex w-full max-w-5xl flex-col items-center justify-center gap-10 text-pretty text-center sm:flex-row sm:gap-24'>
        <div className='flex flex-col gap-10'>
          <h2 className='text-3xl font-medium text-strong sm:text-5xl'>
            Our commitment to you is absolute transparency and simplicity, and
            our pricing is no different.
          </h2>
          <p className='text-xl'>
            We don&apos;t believe in charging based on your invested amount
            (often called Assets Under Management or AUM). AUM-based fees appear
            small initially, but their impact grows with your investment, like a
            parasite. Worst of all, most AUM-based platform and advisor fees are
            deducted from your investments instead of being charged up front in
            a simple transparent manner.
          </p>
        </div>
      </div>
    </section>
  )
}

function DoPay() {
  return (
    <section className='bg-white p-4 py-20 sm:py-40'>
      <div className='mx-auto flex w-full max-w-5xl flex-col items-center justify-center gap-10 text-pretty'>
        <h2 className='flex flex-col text-center text-5xl font-black uppercase leading-none text-strong sm:text-7xl sm:leading-none'>
          fees you do pay
        </h2>
        <div className='grid w-full grid-cols-2 gap-8 text-center'>
          <Card className='col-span-2 flex flex-col items-center gap-8 bg-yellow-300 p-10'>
            <h3 className='text-2xl font-medium text-strong sm:text-4xl'>
              Subscription fees
            </h3>
            <p className='text-xl'>
              Users on a paid plan pay a fixed monthly subscription fee.
            </p>
          </Card>
          <Card className='col-span-2 flex flex-col items-center gap-8 bg-slate-300 p-10 sm:col-span-1'>
            <h3 className='text-2xl font-medium text-strong sm:text-4xl'>
              Brokerage fees
            </h3>
            <p className='text-xl'>
              Whenever you buy or sell financial instruments, like the ETFs that
              back your Fynbos tax-free investments, you pay a brokerage fee
              that includes statutory charges and fees.
            </p>
            <p className='text-xl'>
              We add no markup to the fees we pay for brokerage, so the fee you
              pay is the lowest in the market at 0.10%.
            </p>
          </Card>
          <Card className='col-span-2 flex flex-col items-center  gap-8 bg-indigo-300 p-10 sm:col-span-1'>
            <h3 className='text-2xl font-medium text-strong sm:text-4xl'>
              Fund management fees
            </h3>
            <p className='text-xl'>
              The funds available through Fynbos all have their own fees charged
              by the fund management company. These are not charged directly but
              rather deducted from the fund itself so you won&apos;t see that
              fee in your Fynbos statement.
            </p>
          </Card>
        </div>
      </div>
    </section>
  )
}

export function FAQ() {
  const [profiles, setProfiles] = useState<string[]>([])

  return (
    <section className='bg-slate-100 p-4 py-20 sm:py-40'>
      <div className='mx-auto flex w-full max-w-5xl flex-col items-center justify-center gap-10 text-pretty'>
        <h2 className='flex flex-col text-center text-5xl font-black uppercase leading-none text-strong sm:text-7xl sm:leading-none'>
          Frequently asked questions
        </h2>
        <Accordion
          value={profiles}
          type='multiple'
          className='w-full rounded-card bg-white p-6'
          onValueChange={(val) => setProfiles(val)}
        >
          <AccordionItem value='minimum'>
            <AccordionTrigger>
              What is the minimum amount I can invest?
            </AccordionTrigger>
            <AccordionContent>
              <p>
                The minimum you can invest via EFT is R 100 and the minimum via
                debit order is R 500. Fynbos pays a small fee to process your
                contributions so it is possible that in future we will introduce
                a fee to cover this cost and impose a minimum contribution
                amount to avoid paying the fee.
              </p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value='upgrade'>
            <AccordionTrigger>
              Can I upgrade or downgrade at any time?
            </AccordionTrigger>
            <AccordionContent>
              <p>
                Yes. Your subscription is charged up-front so if you decide to
                downgrade to the free plan you will continue to have access to
                the paid plan until the end of that month.
              </p>
              <p>
                When your account is downgraded at the end of the month, we will
                consolidate all of your savings accounts into your emergency
                savings fund.
              </p>
              <p>
                Any family accounts you have created will become read-only and
                you will no longer be able to allocate your contributions to
                them. To withdraw from family accounts you will need to contact
                our support team.
              </p>
              <p>
                If you upgrade your account you will regain full access to any
                family accounts you created in the past but your previous
                savings pots will not be restored.
              </p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value='payment'>
            <AccordionTrigger>
              How do I pay for my subscription?
            </AccordionTrigger>
            <AccordionContent>
              <p>
                Subscriptions are billed by card. They are not deducted from
                your contributions.
              </p>
              <p>
                If we are unable to charge your card we will notify you and you
                will be given 7 days to pay for your subscription. If we have
                still not received payment then we will automatically downgrade
                your subscription to our free tier.
              </p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value='money'>
            <AccordionTrigger>Who holds my money?</AccordionTrigger>
            <AccordionContent>
              <p>
                Fynbos doesn&apos;t hold your money - your investments go
                directly into carefully selected, trusted funds. This means that
                if Fynbos were to shut down or if you decide to leave, your
                investments remain safe and untouched. You&apos;ll always have
                full control and access to your money. We only act as a guide,
                helping you optimise your savings and investments while ensuring
                your funds are placed in reputable, top-performing investment
                options.
              </p>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </section>
  )
}

const Accordion = AccordionPrimitive.Root

const AccordionItem = forwardRef<
  ElementRef<typeof AccordionPrimitive.Item>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn('my-2 first:mt-0 last-of-type:mb-0', className)}
    {...props}
  />
))
AccordionItem.displayName = 'AccordionItem'

const AccordionTrigger = forwardRef<
  ElementRef<typeof AccordionPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header asChild className='flex hover:bg-slate-50'>
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        'flex w-full items-center justify-between rounded-xl p-4 text-start text-2xl font-medium text-strong [&[data-state=open]>span]:rotate-180',
        className
      )}
      {...props}
    >
      {children}
      <Icon className='transition-transform duration-200'>expand_more</Icon>
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
))
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

const AccordionContent = forwardRef<
  ElementRef<typeof AccordionPrimitive.Content>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={cn(
      'prose overflow-hidden p-4 transition-all data-[state=closed]:animate-accordionUp data-[state=open]:animate-accordionDown prose-p:text-xl',
      className
    )}
    {...props}
  >
    {children}
  </AccordionPrimitive.Content>
))

AccordionContent.displayName = AccordionPrimitive.Content.displayName
